'use strict'

import { Maybe } from '@wix/wix-code-adt'
import seedDataFetcher from './seed'
import { Deferred } from '../helpers'

import { hasUserInputDependencies } from '../filter-resolvers'

const getDeferredDependency = (modeIsSSR, queueMicrotask) => {
  const { promise: deferringDataFetch, resolve: resolveDeferredDataFetcher } =
    new Deferred()
  // https://developer.mozilla.org/en-US/docs/Web/API/HTML_DOM_API/Microtask_guide
  // this way we gaurantee deferred controller(dataset) data fetch request
  // to be executed after createControllers returns controllers to the platform
  if (!modeIsSSR) queueMicrotask(resolveDeferredDataFetcher)
  return deferringDataFetch
}
const getUserInputDependency = () => {
  const { promise: waitingForUserInput, resolve: resolveUserInputDependency } =
    new Deferred()
  return { waitingForUserInput, resolveUserInputDependency }
}

const fetchData = ({
  shouldFetchInitialData,
  recordStore,
  filter,
  datasetIsDeferred,
  modeIsSSR,
  queueMicrotask,
  dependencyManager,
}) => {
  const fetchInitialData = () =>
    shouldFetchInitialData
      ? seedDataFetcher({ recordStore })
      : Promise.resolve(Maybe.Nothing())

  const dependencyResolutionPromise =
    dependencyManager.getDependencyResolutionPromise(filter)

  const deferringDataFetch =
    datasetIsDeferred && getDeferredDependency(modeIsSSR, queueMicrotask)

  const { waitingForUserInput, resolveUserInputDependency } =
    hasUserInputDependencies(filter) && getUserInputDependency()

  const fetchDataDependencies = [
    dependencyResolutionPromise,
    deferringDataFetch,
    waitingForUserInput,
  ].filter(item => Boolean(item))

  const fetchingInitialData = fetchDataDependencies.length
    ? Promise.all(fetchDataDependencies).then(fetchInitialData)
    : fetchInitialData()

  return {
    fetchingInitialData,
    // TODO: Next 2 methods should be combined in a sync single one immediately after fetchingControllerDeps refactoring
    // because we don't need async in a virtual controller
    resolveUserInputDependency: () =>
      resolveUserInputDependency && resolveUserInputDependency(),
  }
}

export default fetchData
