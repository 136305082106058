'use strict'

import { uniq, flow } from 'lodash-es'
import { Maybe } from '@wix/wix-code-adt'
import {
  UPLOAD_BUTTON_ROLE,
  SIGNATURE_INPUT_ROLE,
  DETAILS_DATASET_ROLE,
} from '@wix/wix-data-client-common/src/connection-config/roles'
import { SCOPE_TYPES } from '@wix/dbsm-common/src/scopes/consts'
import {
  getFilter,
  getSort,
  getDatasetStaticConfig,
  isDatasetConfigured,
} from './rootReducer'
import recordActions from '../records/actions'
import dynamicPagesActions from '../dynamic-pages/actions'
import configActions from '../dataset-config/actions'
import rootActions from './actions'
import configureDatasetStore from './configureStore'
import datasetApiCreator from '../dataset-api/datasetApi'
import eventListenersCreator from '../dataset-events/eventListeners'
import syncComponentsWithState from '../side-effects/syncComponentsWithState'
import { getFieldTypeCreator } from '../data/utils'
import createConnectedComponentsStore from '../connected-components'
import {
  adapterApiCreator,
  createComponentAdapterContexts,
  createDetailsRepeatersAdapterContexts,
  initAdapters,
} from '../components'
import { createFilterResolver, createValueResolvers } from '../filter-resolvers'
import wixFormattingCreator from '@wix/wix-code-formatting'
import { createRecordStoreInstance } from '../record-store'
import rootSubscriber from './rootSubscriber'
import dynamicPagesSubscriber from '../dynamic-pages/subscriber'
import createSiblingDynamicPageUrlGetter from '../dynamic-pages/siblingDynamicPageGetterFactory'
import fetchData from './dataFetcher'
import generateRecordFromDefaultComponentValues from '../helpers/generateRecordFromDefaultComponentValues'
import { getCurrentItemIndex, getPageSize } from '../helpers/paginationUtils'
import { getComponentsToUpdate } from '../helpers/livePreviewUtils'
import { Dispatcher, errorHandling } from '../helpers'
import appContext from '../viewer-app-module/DataBindingAppContext'
import { AppError, Trace, reportDatasetActiveOnPage } from '../logger'
import { VerboseMessage } from '../logger/events'
import { extractRealDatasetId } from '../helpers/scopedDatasetUtils'
import { createRecordChangeSubscriber } from './recordChangeSubscriber'
import {
  waitForAllScopedDatasetsToBeReady,
  subscribeDetailsDatasetsToMasterOnReady,
} from '../../lib/dataset-controller/dependencies'

const createDataset =
  (controllerFactory, controllerStore) =>
  ({
    $w,
    controllerConfig,
    datasetType,
    connections,
    connectionsGraph,
    isScoped,
    datasetScope,
    dataProvider,
    dependencyManager,
    firePlatformEvent,
    dynamicPagesData,
    datasetId,
    fixedRecordId,
    recordStoreService,
    parentId,
    updatedCompIds,
    markControllerAsRendered,
    markDatasetDataFetched,
    renderingRegularControllers,
    modeIsLivePreview,
    modeIsSSR,
    useLowerCaseDynamicPageUrl,
    schemasLoading,
    listenersByEvent,
  }) => {
    const isFixedItem = !!fixedRecordId
    const {
      logger,
      platform: {
        user,
        settings: {
          locale,
          env: { renderer },
        },
        timers: { queueMicrotask },
      },
    } = appContext

    const {
      findConnectedComponents,
      setConnectedComponents,
      getConnectedComponents,
    } = createConnectedComponentsStore()
    const unsubscribeHandlers = []

    const { store, subscribe, onIdle } = configureDatasetStore(
      logger,
      datasetId,
    )

    const eventListeners = eventListenersCreator(firePlatformEvent)

    const { fireEvent } = eventListeners
    unsubscribeHandlers.push(eventListeners.dispose)

    // Our system has two event listening system.
    // One is internal, meaning those events can be listened only by our own code
    // And the second one is external, meaning these events are listened by wix code, components, etc.
    // dispatcher - internal, eventListeners - external (legacy)
    // TODO: but before dispatcher was introduced, everything was in eventListeners, so it should be refactored
    const dispatcher = new Dispatcher({
      datasetId: extractRealDatasetId(datasetId),
      scopedDatasetId: isScoped ? datasetId : undefined,
      getState: store.getState,
      getSchema: (name = datasetCollectionName) => dataProvider.getSchema(name),
    })

    const internalEventsUnsubscibers = dispatcher.subscribe(listenersByEvent)

    unsubscribeHandlers.push(...internalEventsUnsubscibers)

    store.dispatch(
      rootActions.init({
        controllerConfig,
        connections,
        isScoped,
        datasetType,
      }),
    )
    const {
      datasetIsVirtual,
      datasetIsReal,
      datasetIsDeferred,
      datasetIsWriteOnly,
      datasetCollectionName,
      dynamicPageNavComponentsShouldBeLinked,
    } = getDatasetStaticConfig(store.getState())

    const filter = getFilter(store.getState())

    const getSchema = (schemaName = datasetCollectionName) => {
      return Maybe.fromNullable(dataProvider.getSchema(schemaName))
    }

    const getFieldType = fieldName => {
      const schema = getSchema(datasetCollectionName)
      const referencedCollectionsSchemas = dataProvider.getReferencedSchemas(
        datasetCollectionName,
      )
      return schema.chain(s =>
        Maybe.fromNullable(
          getFieldTypeCreator(s, referencedCollectionsSchemas)(fieldName),
        ),
      )
    }

    const valueResolvers = createValueResolvers(
      id => dependencyManager.getDependencyById(id, datasetScope),
      getConnectedComponents,
      getFieldType,
    )
    const filterResolver = createFilterResolver(valueResolvers)

    const recordStore = createRecordStoreInstance({
      recordStoreService,
      getFilter: flow(_ => store.getState(), getFilter),
      getSort: flow(_ => store.getState(), getSort),
      getPageSize: () => getPageSize({ state: store.getState() }),
      datasetId,
      filterResolver,
      getSchema,
      fixedRecordId,
    })

    const siblingDynamicPageUrlGetter = dynamicPageNavComponentsShouldBeLinked
      ? createSiblingDynamicPageUrlGetter({
          dataProvider,
          dynamicPagesData,
          collectionName: datasetCollectionName,
          useLowerCaseDynamicPageUrl,
        })
      : null

    if (dynamicPageNavComponentsShouldBeLinked) {
      subscribe(dynamicPagesSubscriber(siblingDynamicPageUrlGetter))
      store.dispatch(dynamicPagesActions.initialize(connections))
    }

    const datasetApi = datasetApiCreator({
      store,
      recordStore,
      eventListeners,
      controllerStore,
      datasetId,
      datasetType,
      isFixedItem,
      siblingDynamicPageUrlGetter,
      onIdle,
      dispatcher,
    })

    const appDatasetApi = datasetApi(false)
    const componentAdapterContexts = []

    const adapterParams = {
      getState: store.getState,
      datasetApi: appDatasetApi,
      eventListeners,
      dispatcher,
      getFieldType,
      getSchema,
      controllerFactory,
      controllerStore,
      PresetVerboseMessage: VerboseMessage.with({
        collectionId: datasetCollectionName,
        parentId,
      }),
      parentId,
      modeIsLivePreview,
      wixFormatter:
        (modeIsSSR && renderer === 'bolt') || !locale
          ? null
          : wixFormattingCreator({
              locale,
            }),
    }
    const adapterApi = adapterApiCreator({
      dispatch: store.dispatch,
      recordStore,
      componentAdapterContexts,
    })

    unsubscribeHandlers.push(
      recordStoreService
        .map(service =>
          service.onChange(
            createRecordChangeSubscriber(store.getState, store.dispatch),
          ),
        )
        .getOrElse(() => {}),
    )
    const { fetchingInitialData, resolveUserInputDependency } = fetchData({
      dependencyManager,
      shouldFetchInitialData: controllerConfig && !datasetIsWriteOnly,
      recordStore,
      filter,
      datasetIsDeferred,
      modeIsSSR,
      queueMicrotask,
    })

    fetchingInitialData.then(() => {
      markDatasetDataFetched()
      const firstRecord = recordStore().fold(
        () => undefined,
        service =>
          service.getSeedRecords().matchWith({
            Empty: () => undefined,
            Results: ({ items }) => items[0],
          }),
      )
      if (firstRecord) {
        store.dispatch(recordActions.setCurrentRecord(firstRecord, 0))
      }
    })

    const isScopedDetailsDataset = isScoped && !isFixedItem
    if (isScopedDetailsDataset) {
      dependencyManager
        .getDependenciesByFilter(filter, datasetScope)
        .forEach(({ masterDataset: { api: masterDatasetApi } }) => {
          subscribeDetailsDatasetsToMasterOnReady({
            detailsDatasetApis: [appDatasetApi],
            store,
            masterDatasetApi,
            controllerConfig,
            unsubscribeHandlers,
          })
        })
    }

    const shouldRefreshDataset = () => {
      const currentRecordIndex = getCurrentItemIndex({
        state: store.getState(),
      })
      const isPristine = recordStore().fold(
        () => false,
        service => service.isPristine(currentRecordIndex),
      )

      return isPristine && !datasetIsWriteOnly
    }

    const pageReady = async function () {
      user.onLogin(() => {
        // THIS SHOULD HAPPEN SYNCHRONOUSLY SO TESTS WILL REMAIN MEANINGFUL
        // IF YOU EVER FIND THE NEED TO MAKE IT ASYNC - TALK TO leeor@wix.com
        if (shouldRefreshDataset()) {
          appDatasetApi.refresh()
        }
      })

      setConnectedComponents(
        getComponentsToUpdate({
          updatedCompIds,
          datasetIsReal,
          connectionsGraph,
          connectedComponents: findConnectedComponents(
            uniq(connections.map(c => c.role)),
            $w,
          ),
        }),
      )

      subscribeDetailsDatasetsToMasterOnReady({
        detailsDatasetApis: getConnectedComponents()
          .filter(({ role }) => role === DETAILS_DATASET_ROLE)
          .map(({ component }) => component),
        masterDatasetApi: appDatasetApi,
        dependencyManager,
        controllerConfig,
        unsubscribeHandlers,
      })

      if (datasetIsReal) {
        await schemasLoading
      }

      resolveUserInputDependency()

      if (
        // router dataset, without router data
        !isDatasetConfigured(store.getState()) ||
        // !controllerConfig.dataset.collectionName ||
        // removed collection, nothing to bind.
        !dataProvider.hasSchema(controllerConfig.dataset.collectionName)
      ) {
        fetchingInitialData.then(() => {
          markControllerAsRendered()
          dependencyManager.resolveDependants(datasetId)
          store.dispatch(configActions.setIsDatasetReady(true))
          fireEvent('datasetReady')
        })

        return Promise.resolve()
      }

      const masterIds = dependencyManager
        .getDependenciesByFilter(filter)
        .map(dependency => dependency.masterDatasetId)

      componentAdapterContexts.push(
        ...createComponentAdapterContexts({
          datasetId,
          masterIds,
          connectionsGraph,
          connectedComponents: getConnectedComponents(),
          $w,
          adapterApi,
          getFieldType,
          ignoreItemsInRepeater: datasetIsReal,
          adapterParams,
        }),
      )

      if (datasetIsReal) {
        //TODO: add additional check by master dataset
        const detailsRepeatersAdapterContexts =
          createDetailsRepeatersAdapterContexts({
            datasetId,
            masterIds,
            connectionsGraph,
            connectedComponents: getConnectedComponents(),
            getFieldType,
            adapterParams,
          })
        componentAdapterContexts.push(...detailsRepeatersAdapterContexts)
      }

      subscribe(
        rootSubscriber(
          recordStore,
          adapterApi,
          getFieldType,
          eventListeners.executeHooks,
          datasetId,
          componentAdapterContexts,
          fireEvent,
          dispatcher,
        ),
      )

      unsubscribeHandlers.push(
        syncComponentsWithState(
          store,
          componentAdapterContexts,
          logger,
          datasetId,
          recordStore,
        ),
      )

      const defaultRecord = generateRecordFromDefaultComponentValues(
        componentAdapterContexts.filter(
          ({ role }) =>
            ![UPLOAD_BUTTON_ROLE, SIGNATURE_INPUT_ROLE].includes(role),
        ),
      )

      store.dispatch(recordActions.setDefaultRecord(defaultRecord))
      if (datasetIsWriteOnly) {
        await store.dispatch(recordActions.initWriteOnly(datasetIsVirtual))
      }

      if (datasetIsDeferred) {
        // we should hide all components connected to deferred dataset before telling the Platform we are ready
        adapterApi().hideComponent({ rememberInitiallyHidden: true })

        if (modeIsSSR) adapterApi().clearComponent()
      }

      const pageReadyResult = fetchingInitialData.then(async () => {
        if (datasetIsDeferred) {
          await renderingRegularControllers
        }

        if (!modeIsSSR) {
          try {
            reportDatasetActiveOnPage(
              store.getState(),
              connections,
              datasetType,
              datasetIsVirtual,
              datasetId,
            )
          } catch (err) {
            logger.log(
              new AppError('Failed to report dataset active BI', {
                cause: err,
              }),
            )
          }
        }
        await initAdapters(adapterApi())
        if (datasetIsReal) {
          await waitForAllScopedDatasetsToBeReady(controllerStore)
        }
        if (datasetIsDeferred) {
          // we should show all components connected to deferred dataset only after all child controllers (repeater items) are ready
          adapterApi().showComponent({ ignoreInitiallyHidden: true })
        }
        dependencyManager.resolveDependants(datasetId)
        store.dispatch(configActions.setIsDatasetReady(true))
        fireEvent('datasetReady')
      })

      if (datasetIsDeferred) {
        markControllerAsRendered()

        return Promise.resolve()
      } else {
        pageReadyResult.then(markControllerAsRendered)

        return pageReadyResult
      }
    }

    const userCodeDatasetApi = datasetApi(true)
    const dynamicExports = (scope /*, $w*/) => {
      switch (scope.type) {
        case SCOPE_TYPES.COMPONENT:
          return userCodeDatasetApi.inScope(
            scope.compId,
            scope.additionalData.itemId,
          )
        default:
          return userCodeDatasetApi
      }
    }

    const dispose = () => {
      componentAdapterContexts.splice(0)
      unsubscribeHandlers.forEach(h => h())
    }

    const finalPageReady = datasetIsVirtual
      ? pageReady
      : () => logger.log(new Trace('dataset/pageReady', pageReady))

    return {
      pageReady: errorHandling(finalPageReady, e =>
        logger.logError(e, 'Dataset pageReady callback failed', { datasetId }),
      ),
      exports: dynamicExports,
      staticExports: userCodeDatasetApi,
      dispose,
      api: appDatasetApi,
    }
  }

export default createDataset
